<template>
    <b-overlay :show="loading">
      <print-modal @submit="printDisposal" />
        <b-card
        >
          <b-row class="justify-content-between">
            <b-col md="6" sm="12" class="my-1">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                @click.prevent="$router.push(`${$route.path}/add`)"
                variant="primary"
                v-if="allowCreate()"
              >
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah
              </b-button>
              
            </b-col>
            <b-col md="6" sm="12" class="my-1 text-right">
              <b-button
                @click.prevent="$bvModal.show('print-modal')"
                variant="outline-primary"
              >
                <feather-icon icon="PrinterIcon" class="mr-50" />
                Cetak
              </b-button>
              
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" class="my-1">
              <b-form-group
                :label="$t('Filter')"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                label-for="filterInput"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
      
            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="disposals"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
              >
                <template #cell(order)="row">
                  <strong class="text-center">
                    {{ (row.index + 1) }}
                  </strong>
                </template>
    
                <template #cell(nama)="{item}">
                    <router-link :to="`/asset/detail/${item.id}`" v-if="item.penyimpanan && item.penyimpanan.asset">
                        {{  item.penyimpanan.asset.nama  }}
                    </router-link>
                    <i class="text-danger" v-else>Aset tidak ditemukan</i>
                </template>
                <template #cell(satuan)="{item}">
                    <span v-if="item.penyimpanan && item.penyimpanan.asset && item.penyimpanan.asset.satuan">
                    {{ item.penyimpanan.asset.satuan.satuan }}</span>
                </template>
      
                <template #cell(actions)="{item}">
                    <b-button variant="outline-info" class="mr-1" size="sm" @click.prevent="$router.push(`/asset-disposal/${jenisDisposal(item)}/detail/${item.id}`)">
                        <feather-icon icon="EyeIcon" />
                    </b-button>
                      <b-button
                        v-if="allowDelete()"
                        v-b-tooltip.hover.right="'Hapus'"
                        size="sm"
                        @click="remove(item)"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                </template>
              </b-table>
            </b-col>
      
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card>
    </b-overlay>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import PrintModal from './components/PrintModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
BTable,
BFormTextarea,
BOverlay,
VBTooltip,
BDropdown,
BDropdownItem,
BAvatar,
BBadge,
BModal,
BRow,
BCard,
BCol,
BFormGroup,
BFormSelect,
BPagination,
BInputGroup,
BFormInput,
BInputGroupAppend,
BButton,
BFormCheckbox,
} from "bootstrap-vue"
export default {
    components: {
        PrintModal,
        BCard,ToastificationContent,
        VBTooltip,
        BModal,
        BDropdown,
        BDropdownItem,
        BTable,
        BFormTextarea,
        BOverlay,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormCheckbox
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data: () => ({
        fields: [
            { key: 'order', label: 'No' },
            { key: 'no', label: 'No. Disposal' },
            { key: 'tanggal', label: 'Tanggal Disposal' },
            { key: 'nama', label: 'Aset' },
            { key: 'jumlah', label: 'Jumlah' },
            { key: 'satuan', label: 'Satuan' },
            { key: 'actions', label: 'Aksi' }
        ],
        loading: false,
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "asc",
        filter: null,
        filterOn: [],
        disposals: [],
        confirmOption: {
          title: 'Anda yakin?',
          text: `Data disposal ini akan dihapus`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }
    }),
    watch: {
      "$route.path"(val) {
        this.getDisposal()
      }
    },
    methods: {
      jenisDisposal(item) {
        return item.jenis == 1 ? 'hilang' : 'rusak'
      },
      async printDisposal({tgl_awal, tgl_akhir}) {
          const params = {
              jenis: this.$route.meta.jenis,
              tgl_awal,
              tgl_akhir
          }
          if(this.myGudang) params.id_gudang = this.myGudang.id

          try {
            this.loading = true
            this.$bvModal.hide('print-modal')
            const response = await this.$store.dispatch('asset/printDisposal', params)
            this.loading = false
            window.open(URL.createObjectURL(response))
          }
          catch(e) {
            this.loading = false
            this.displayError(e)
            return false
          }
      },
      remove(item) {
        this.$swal(this.confirmOption)
        .then(async res => {
          if(res.value) {
            const payload = {
              id: item.id,
              fungsi: 2
            }
            try {
              this.loading = true
              await this.$store.dispatch('asset/saveDisposal', [payload])
              this.displaySuccess({
                message: 'Disposal berhasil dihapus!'
              })
              this.getDisposal()
              this.loading = false
            }
            catch(e) {
              this.loading = false
              this.displayError(e)
              return false
            }
          }
        })
      },
        async getDisposal() {
            this.loading = true
            const jenis = this.$route.meta.jenis
            const params = {
                jenis
            }
            if(this.myGudang) params.id_gudang = this.myGudang.id
            this.disposals = await this.$store.dispatch('asset/getDisposal', params)
            this.loading = false
        },
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
      },
    },
    mounted() {
        this.getDisposal()
    }
}
</script>